import { UserStoreInterface } from './user.interface';

const lang = 'es';

const initialUserData = {
    email: '',
    id: '',
    name: '',
    lastName: ''
};

export const UserDefault: UserStoreInterface = {
    userData: initialUserData,
    isLoged: false,
    defaultProfile: 0,
    preferences: {
        lang
    }
};

import { configureStore } from '@reduxjs/toolkit';
import user from './slices/user';
import toast from './slices/toast';

export const store = configureStore({
    reducer: {
        user,
        toast
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
